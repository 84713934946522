import React from 'react';
import AstroLink from "../../lib/AstroLink";
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image";

const ProductListItem = ({ index, productItem }) => {
    const { mainImage, heroImage, title, slug } = productItem.node;
    return (
        <div>
            <div className="uk-height-min-large uk-transition-toggle uk-position-relative uk-overflow-hidden" data-uk-scrollspy={index !== 0 ? "cls:uk-animation-fade" : null}>
                <Img
                    fluid={mainImage.fluid}
                    alt={mainImage.title}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    style={{ height: "100%" }}
                />
                <AstroLink
                    style={{ textDecoration: "none" }}
                    className={`uk-transition-fade uk-position-cover uk-overlay uk-padding-remove`}
                    to={`/products/${slug}/`}>

                    <BackgroundImage
                        Tag="div"
                        fluid={heroImage ? heroImage.fluid : mainImage.fluid}
                        preserveStackingContext={true}
                        className={`uk-height-1-1 uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}
                        style={{
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: `center center`,
                            height: "100%",
                            width: "100%"
                        }}
                        data-uk-parallax="scale: 1,1.25">
                        <div style={{ backgroundColor: "rgba(29, 38, 54, 0.4)" }} className="uk-position-cover disable-select" />
                        <div className="uk-width-3-5@s uk-text-center uk-position-relative">
                            <h3 className="uk-text-bold uk-text-astro-lightest">
                                {title}
                            </h3>
                        </div>

                    </BackgroundImage>
                </AstroLink>
            </div>
        </div>
    )
}
export default ProductListItem;
