import React from 'react';
import ProductListItem from './ProductListItem';


const ProductsList = ({data}) => {
    const productNodes = data.sort((a, b) => new Date(b.node.date).getTime() - new Date(a.node.date).getTime());

    return (
        <section className="uk-padding-remove-bottom uk-background-astro-lightest">
            <div className="uk-container uk-container-expand">
                    <div className={`uk-grid uk-grid-xxsmall uk-grid-row-xxsmall uk-grid-match uk-child-width-1-2@s`}>
                        {productNodes.map((post, i) =>
                            <ProductListItem
                                key={i}
                                index={i}
                                productItem={post}
                            />
                        )}
                    </div>
            </div>
        </section>
    );
}

export default ProductsList;
