import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet"
import Layout from "../Layout";
import ProductsList from '../components/Products/ProductsList';
import FullscreenHeader from "../components/Content/FullscreenHeader";

const Products = ({ data, location, ...props }) => {
    const products = data.allContentfulProducts.edges
    const title = `Products and services made for great User Experiences.`
    const description = `Creative technology and UX products and services developed by Michael Lisboa.`
    return (
        <Layout location={location}>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="twitter:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />
            </Helmet>

            <FullscreenHeader
                media={data.allFile.nodes[0]}
                content={'<h1 class="h1__xlarge">We make <span class="uk-text-nowrap">cool stuff.</span><br />Just for fun.</h1><p class="uk-text-lead">Let\'s have fun.</p>'}
            />
            <div className="uk-margin-xxsmall"><ProductsList data={products} /></div>
        </Layout>
    )
}

export default Products;
export const productsQuery = graphql`
    query productsQuery {
        allFile(filter: {name: {eq: "mypic_products"}}) {
            nodes {
              name
              publicURL
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              internal {
                mediaType
              }
            }
          }
        allContentfulProducts {
            edges {
                node {
                    title
                    mainImage {
                        fluid(maxWidth: 800) {
                          ...GatsbyContentfulFluid_withWebp
                          src
                        }
                        title
                    }
                    heroImage {
                        fluid(maxWidth: 800) {
                          ...GatsbyContentfulFluid_withWebp
                          src
                        }
                        title
                    }
                    date
                    subtitle {
                        subtitle
                    }
                    slug
                }
            }
        }
        allContentfulImageCollection(filter: {
            slug: {
                eq: "tool_logo_set"
            }
        })
        {
            edges {
                node {
                    images {
                        fluid(maxWidth: 200, quality: 80) {
                          ...GatsbyContentfulFluid_withWebp
                        }
                        title
                    }
                }
            }
        }
    }
`
